<template>
    <div class="quot">
        <van-nav-bar :border="false" :title="$t('行情')">
        </van-nav-bar>
        <div class="quot-first">
            <div class="robot-card" v-if="robot_info">
                <div class="robot-card_hd flex-center">
                    <img src="../../assets/pictures/avatar-default.png" class="ro-logo">
                    <div class="strong" v-if="robot_info.r_count > 0">
                        {{$t('当前共有')}} <span class="text-orange">{{robot_info.r_count}}</span> {{$t('台')}}
                        {{$t('机器人自动做单中')}}
                    </div>
                    <div v-else>{{$t('您当前没有机器人正在工作')}}</div>
                </div>
                <div class="quo-body">
                    <div class="quo-item">
                        <img src="../../assets/pictures/huiyuanjibie.png" alt="">
                        <span>{{$t('当前机器人级别')}}：</span>
                        <span class="text-red-tag">{{$t(robot_info.robot.name)}}</span>
                    </div>
                    <div class="quo-item">
                        <img src="../../assets/pictures/yiwen.png" alt="">
                        <span>{{$t('机器人有效期')}}：</span>
                        <span class="text-red-tag">
                            {{!robot_info.robot.use_time ? '0' :
                            (Math.floor(robot_info.robot.use_time / 86400) + ' / ' + robot_info.robot.days)}} {{$t('天')}}
                        </span>
                    </div>
                    <div class="quo-item">
                        <img src="../../assets/pictures/zhuangtai.png" alt="">
                        <span>{{$t('状态')}}：</span>
                        <span class="text-red-tag">
                            <template v-if="robot_info.r_count > 0">{{$t('正常')}}</template>
                            <template v-else>{{$t('停用')}}</template>
                        </span>
                    </div>
                    <div class="quo-item">
                        <img src="../../assets/pictures/zijin.png" alt="">
                        <span>{{$t('做单价格')}}：</span>
                        <span class="text-red-tag">
                            {{!robot_info.robot.min_money ? '' :
                            (parseFloat(robot_info.robot.min_money).toFixed(2) + $t('元/单'))}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-two">
            <div class="bs-panel">
                <div class="bs-panel_body">
                    <div class="quo-bar flex-center">
                        <div class="flex_bd flex-ex-bar">
                            <div class="expace-list">
                                <div class="list-card" v-for="(item, index) in exchange" :key="index" >
                                    <img :src="item.logo">
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-plus" @click="show = true">
                            <span class="iconfont icon-plus"></span>
                        </div>
                    </div>
                    <div class="rec-cell flex-center" v-for="(item, index) in hq_info" :key="index">
                        <img :src="item.logo" class="rec-icon">
                        <div class="flex_bd">
                            <div class="text">{{item.name}}</div>
                        </div>
                        <div class="flex_bd">
                            <div class="text">{{item.close}}</div>
                        </div>
                        <div class="rec-right">
                            <!--                        <div class="text">{{item.close}}</div>-->
                            <div class="rec-per" :class="item.ratio >  0 ?'text-green':'text-red'">
                                <span>{{parseFloat(item.ratio).toFixed(2)}}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-main">

            <div class="rec-list-panel">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <div class="rec-list-card" v-for="(item, index) in list" :key="index">
                        <div class="flex-center u-m-b10">
                            <img :src="item.currency.logo" class="rec-icon">
                            <div class="flex_bd" v-if="item.currency">
                                <div class="rec-tit">{{item.currency.name}}</div>
                                <div class="rec-time text-gray">{{item.updated_at}}</div>
                            </div>
                        </div>
                        <div class="flex-center">
                            <div class="rec-iconfont">
                                <i class="iconfont"  :class="item.profit >= 0 ? 'icon-up':'icon-dn'"></i>
                            </div>
                            <div class="rec-right">
                                <div class="text text-gray">{{$t('收益')}}：</div>
                                <div class="rec-per" :class="item.profit >= 0 ? 'text-green':'text-red'">
                                    {{parseFloat(item.profit).toFixed(6)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
        <Foot :active="1"/>
        <van-popup class="page-popup" v-model="show" position="center" closeable :style="{ borderRadius: '10px', width:'90%'}">
            <div class="quot-dialog">
                <div class="quot-title">{{$t('前往交易所')}}</div>
                <div class="quot-body">
                    <van-row class="exp-rows" :gutter="20">
                        <van-col :span="12" v-for="(item, index) in exchange" :key="index">
                            <van-cell center clickable @click="toggle(item.url ? item.url : '')" is-link>
                                <template #title>
                                    <img :src="item.logo" alt="" class="ex-icon">
                                </template>
                            </van-cell>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";

    export default {
        components: {Foot},
        data() {
            return {
                b_timer: '',
                b_load: false,
                show: false,
                name: '',
                list: [],
                hq_loading: true,
                loading: false,
                finished: false,
                page: 0,
                size: 20,
                robot_info: null,
                exchange: [],
                result: [],
                hq_info: [],
            }
        },
        methods: {
            toggle(url) {
                if (url) {
                    window.open(url, '_blank');
                }
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            getB() {
                if (this.b_load) {
                    return;
                }
                this.b_load = true;
                this.$axios.get(this.$api_url.get_b).then((res) => {
                    this.hq_info = res.data;
                    this.hq_loading = false;
                    this.b_load = false;
                });
            },
            getData() {
                this.$axios
                    .get('/robot/run-info' + '?page=' + this.page)
                    .then((res) => {
                        this.robot_info = res.data.data.robot_info;
                        this.exchange = res.data.data.b_exchange;
                    });
            },
            onLoad() {
                this.$axios
                    .get(this.$api_url.order_list + '?page=' + this.page)
                    .then((res) => {
                        this.page = this.page + 1;
                        this.list = this.list.concat(res.data.data);
                        if (res.data.data.length < this.size) {
                            this.finished = true;
                        }
                        this.loading = false;
                    });
            }
        },
        mounted() {
            this.getData();
            this.b_timer = setInterval(() => {
                this.getB()
            }, 1000);
        },
        destroyed() {
            clearInterval(this.b_timer);
        },
    }
</script>
<style scoped>
    .rec-list-panel{
        font-size: 14px;
    }
    .quot-first{
        position: relative;
        z-index: 10;
        background: url(../../assets/pictures/hangqing_banner.jpg) center top no-repeat;
        background-size: cover;
        padding: 70px 20px 20px 20px;
        margin-top: -60px;
        border-radius: 0 0 20px 20px;
    }
    .page-two{
        margin-top: -20px;
        padding-top: 20px;
        background-color: #1a2331;
        border-radius: 0 0 20px 20px;
    }
    .quot .van-nav-bar{
        background: transparent;
    }
    .robot-card{
        padding: 20px;
        border-radius: 15px;
        font-size: 14px;
        overflow: hidden;
        background: url(../../assets/pictures/trend-card.jpg) center no-repeat;
    }
    .robot-card_hd{
        background-color: #3553ea;
        padding: 0 15px;
        border-radius: 30px;
        color: #ffffff;
        margin-bottom: 15px;
    }
    .robot-card_hd .ro-logo{
        width: 36px;
        height: 36px;
        margin-right: 10px;
    }
    .quo-body{
        padding: 0 20px;
    }
    .quo-item{
        display: flex;
        align-items: center;
        padding: 5px 0;
    }
    .quo-item img{
        width: 18px;
        margin-right: 10px;
    }
    .expace-list{
        display: flex;
        white-space: nowrap;
    }
    .page-two .bs-panel_body{
        padding: 15px 20px;
    }
    .list-card{
        display: inline-flex;
        background-color: #345fe6;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 36px;
        min-width: 100px;
        border: 1px solid #282829;
        margin-right: 10px;
    }
    .rec-cell{
        padding: 15px 0;
        border-bottom: 1px solid #313132;
    }
    .rec-icon{
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }
    .rec-iconfont{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }
    .rec-list-card{
        border-radius: 10px;
        padding: 15px;
        background-color: #252f46;
        margin-bottom: 15px;
    }
    .rec-list-card .rec-tit{
        font-weight: bold;
        margin-bottom: 5px;
    }
    .rec-right{
        display: flex;
        align-items: center;
    }
    .expace-list{
        padding: 5px 0;
    }
    .expace-list img{
        width: 100px;
        height: 30px;
    }
    .btn-plus{
        max-width: 32px;
        height: 32px;
        border: 1px solid #252f46;
        color: #ffffff;
        margin-left: 10px;
        border-radius: 6px;
    }
    .flex-ex-bar{
        overflow-x: auto;
    }

    .quot-dialog{
        padding: 15px;
    }
    .quot-dialog ::v-deep .van-cell{
        background-color:#345fe6;
        border-radius: 10px;
        color: #fff;
        margin-bottom: 10px;
    }
    .quot-title{
        text-align: center;
        font-size: 18px;
        padding: 10px 0;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .ex-icon{
        display: block;
        height: 30px;
    }
    .text-red-tag{
        display: inline-flex;
        align-items: center;
        background-color: #fb5b5b;
        font-size: 12px;
        color: #ffffff;
        border-radius: 20px;
        padding: 0 10px;
    }









</style>






