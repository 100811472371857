import { render, staticRenderFns } from "./robot-run-page.vue?vue&type=template&id=5f138cce&scoped=true&"
import script from "./robot-run-page.vue?vue&type=script&lang=js&"
export * from "./robot-run-page.vue?vue&type=script&lang=js&"
import style0 from "./robot-run-page.vue?vue&type=style&index=0&id=5f138cce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f138cce",
  null
  
)

export default component.exports